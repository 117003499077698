import * as React from 'react';
import { useField } from 'formik';
import { FilterSelect } from '../Select';

export const FilterSelectField = props => {
  const { name, options, onBlur } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <FilterSelect
      {...field}
      {...props}
      options={options}
      invalid={!!(meta.touched && meta.error)}
      onBlur={() => {
        helpers.setTouched(true);
        onBlur && onBlur();
      }}
      onChange={value => {
        helpers.setValue(value);
      }}
    />
  );
};
