import React from 'react';
import DatePickerOrigin from 'react-datepicker/dist/react-datepicker';
import styled from 'styled-components/macro';
import raw from 'raw.macro';
import { useTranslation } from 'react-i18next';

import { inputStyles } from '../Input';

const datePickerStyles = raw(`react-datepicker/dist/react-datepicker.css`);

export const DatePicker = props => {
  const { t } = useTranslation();
  const { onChange, value, onBlur, invalid } = props;

  return (
    <Wrapper>
      <StyledDatePicker
        dateFormat="dd.MM.yyyy"
        selected={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={t('placeholder.DDMMYYYY')}
        invalid={invalid}
      />
    </Wrapper>
  );
};

const StyledDatePicker = styled(DatePickerOrigin)`
  ${inputStyles}
`;

const Wrapper = styled.div`
  ${datePickerStyles}

  .react-datepicker-popper {
  }

  .react-datepicker {
    background: var(--grey-700);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  .react-datepicker__header {
    background: var(--grey-600);
    border: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: var(--white);
  }

  .react-datepicker__day:hover {
    background: var(--grey-600);
    color: var(--white);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: var(--gold-30) !important;
  }

  .react-datepicker__day--outside-month {
    color: var(--grey-500);
  }

  .react-datepicker__navigation-icon {
    top: 3px;

    &:before {
      border-width: 1px 1px 0 0;
    }
  }
`;
