import React from 'react';
import { AxiosResponse } from 'axios';
import { useField } from 'formik';

import { api } from '../../../services/api';
import { debounce } from '../../../utils/debounce';
import { AsyncSelect } from '../Select';

import { toOptions } from '../../../utils/to-options';
import { Col, Row } from '../Grid';
import { Label } from '../Label';

export function AsyncSelectField(props) {
  const { label, name, source, optionKeys, getOption, listKey } = props;
  const [field, meta, helpers] = useField(name);

  const _promiseOptions = async (inputValue: string) => {
    try {
      const result = (await api.get(source, {
        params: { take: 40, skip: 0, needle: inputValue },
      })) as AxiosResponse;

      return getOption
        ? result.data[listKey || 'data'].map(getOption)
        : toOptions(result.data[listKey || 'data'], optionKeys);
    } catch (error) {
      return [];
    }
  };
  const promiseOptions = debounce(_promiseOptions, 300);

  return (
    <Row flexDirection="column">
      <Col>
        <Label>{label}</Label>
      </Col>
      <Col gutterY={0.5}>
        <AsyncSelect
          {...field}
          {...props}
          onChange={value => {
            helpers.setValue(value);
          }}
          loadOptions={promiseOptions}
          invalid={!!(meta.touched && meta.error)}
          onBlur={() => {
            helpers.setTouched(true);
          }}
        />
      </Col>
    </Row>
  );
}
