import React, { useContext } from 'react';
import { getIn, useField } from 'formik';
import ImageUploading from '../ImageUploading';
import prettyBytes from 'pretty-bytes';

import { ListItem } from '../ListItem';
import { Col, Row } from '../Grid';
import { Ellipsis } from '../Ellipsis';
import { ButtonIcon } from '../ButtonIcon';
import { Icon } from '../Icon';
import { Button } from '../Button';
import { UploadsContext } from '../../../context/UploadsContext';
import { getUploadId } from '../../../utils/get-upload-id';

export const FileField = props => {
  const { label, name, border, primary, allowNonImageType } = props;
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { uploads } = useContext(UploadsContext);

  const onDelete = () => {
    helpers.setValue(null);
  };

  const onAdd = files => {
    helpers.setValue(files[0]);
  };

  return (
    <Row>
      {value ? (
        <Col xs={6}>
          <ListItem>
            <Row>
              <Col xs={6}>
                <Ellipsis>
                  {getIn(value, 'file.name') || getIn(value, 'original_name')}
                </Ellipsis>
              </Col>
              <Col xs="auto">
                {prettyBytes(
                  +getIn(value, 'file.size') || +getIn(value, 'size') || 0,
                )}
              </Col>
              <Col>
                <ButtonIcon
                  block
                  onClick={onDelete}
                  $loading={
                    value?.file &&
                    uploads.indexOf(getUploadId(value.file)) !== -1
                  }
                >
                  <Icon name="cross" />
                </ButtonIcon>
              </Col>
            </Row>
          </ListItem>
        </Col>
      ) : (
        <Col xs={12}>
          <ImageUploading
            value={[]}
            onChange={onAdd}
            allowNonImageType={allowNonImageType}
          >
            {({ onImageUpload, dragProps }) => (
              <Button
                onClick={() => {
                  onImageUpload();
                  helpers.setTouched(true);
                }}
                border={border}
                primary={primary}
                invalid={!!(meta.touched && meta.error)}
                {...dragProps}
              >
                {label}
              </Button>
            )}
          </ImageUploading>
        </Col>
      )}
    </Row>
  );
};
