import * as React from 'react';
import { useField } from 'formik';

import { FilterSlider } from '../FilterSlider';

export const FilterSliderField = props => {
  const { name, onBlur } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <FilterSlider
      {...field}
      {...props}
      invalid={!!(meta.touched && meta.error)}
      onBlur={() => {
        helpers.setTouched(true);
        onBlur && onBlur();
      }}
      onChange={value => {
        helpers.setValue(value);
      }}
    />
  );
};
