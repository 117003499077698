import * as React from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import RelativePortal from 'react-relative-portal';

import { Button } from '../Button';
import { InputNumber } from '../Input';
import { Col, Row } from '../Grid';
import { Slider } from '../Slider';
import { Icon, IconWrapper } from '../Icon';

interface Props {
  label: string;
  min: number;
  max: number;
  value?: [number, number];
}

export function FilterSlider(props) {
  const { label, min, max, value, onChange, onBlur, unit, prefix } = props;
  const [valueState, changeValueState] = React.useState(value);
  const [inputValueState, changeInputValueState] = React.useState(value);
  const [isOpen, changeOpenState] = React.useState(false);

  const toggleMenu = () => {
    if (isOpen) {
      onChange && onChange(inputValueState);
      onBlur && onBlur();
    }
    changeOpenState(!isOpen);
  };

  const hasValue = min !== valueState[0] || max !== valueState[1];

  /*const ref = React.useRef(null);
  useClickAway(ref, () => {
    if (isOpen) toggleMenu();
  });*/

  const onOutClick = () => {
    if (isOpen) toggleMenu();
  };

  React.useEffect(() => {
    changeValueState(value);
    changeInputValueState(value);
  }, value);

  const maxLength = `${max}`.length + (prefix ? 1 : 0);
  let inputWidth = maxLength * 11.2 + 24;
  inputWidth = inputWidth > 84 ? inputWidth : 84;

  return (
    <Wrapper>
      <RangeButton
        onClick={toggleMenu}
        active={isOpen}
        hasValue={hasValue}
        iconEnd
      >
        {label}
        <Icon name="chevron" />
      </RangeButton>
      <RelativePortal component="div" onOutClick={onOutClick}>
        {isOpen ? (
          <Menu>
            <Row>
              <Col xs={12}>
                <Row gutter={0.5} alignItems="center">
                  <Col gutter={0.5}>
                    <RangeInput
                      thousandSeparator
                      value={inputValueState[0]}
                      isAllowed={({ floatValue }) =>
                        !floatValue ||
                        (!!floatValue && floatValue >= min && floatValue <= max)
                      }
                      onValueChange={({ floatValue }) => {
                        changeInputValueState([floatValue, inputValueState[1]]);
                      }}
                      onBlur={() => {
                        changeValueState(inputValueState);
                      }}
                      allowNegative={false}
                      prefix={prefix}
                      style={{ width: inputWidth }}
                    />
                  </Col>
                  <Col gutter={0.5}>–</Col>
                  <Col gutter={0.5}>
                    <RangeInput
                      thousandSeparator
                      value={inputValueState[1]}
                      isAllowed={({ floatValue }) =>
                        !floatValue ||
                        (!!floatValue && floatValue >= min && floatValue <= max)
                      }
                      onValueChange={({ floatValue }) => {
                        changeInputValueState([inputValueState[0], floatValue]);
                      }}
                      onBlur={() => {
                        changeValueState(inputValueState);
                      }}
                      allowNegative={false}
                      prefix={prefix}
                      style={{ width: inputWidth }}
                    />
                  </Col>
                  {unit ? <Col gutter={0.5}>{unit}</Col> : null}
                </Row>
              </Col>
              <Col xs={12} gutterY={1}>
                <Slider
                  min={min}
                  max={max}
                  range
                  value={valueState}
                  onChange={value => {
                    changeValueState(value);
                    changeInputValueState(value);
                  }}
                />
              </Col>
            </Row>
          </Menu>
        ) : null}
      </RelativePortal>
    </Wrapper>
  );
}

FilterSlider.defaultProps = {
  label: 'Duolingo Score',
  min: 0,
  max: 10,
  value: [0, 10],
};

const Wrapper = styled.div`
  position: relative;
`;

const Menu = styled.div`
  //width: 240px;
  margin-top: 0.25rem;
  padding: 1.5rem;
  background: var(--grey-700);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  z-index: 1;
`;

const RangeButton = styled(Button)<{ active?: boolean; hasValue?: boolean }>`
  border-color: var(--grey-600) !important;
  padding-right: 40px !important;
  cursor: default;

  &:hover {
    border-color: var(--gold-30);
  }

  ${IconWrapper} {
    position: absolute;
    padding: 10px 8px;
    right: 0;
    top: 0;
    line-height: 0;
    display: block;
    transition: transform 0.2s;

    svg {
      width: 24px;
      height: 24px;
      margin: 0;
    }
  }

  ${ifProp(
    'hasValue',
    css`
      border-color: var(--gold-30) !important;
    `,
  )}

  ${ifProp(
    'active',
    css`
      border-color: var(--gold-30) !important;
      background-color: var(--gold-30) !important;

      ${IconWrapper} {
        transform: rotate(180deg);
      }
    `,
  )}
`;

const RangeInput = styled(InputNumber)`
  background-color: var(--grey-600);

  &:hover,
  &:focus {
    border-color: var(--gold-30);
  }
`;
