import styled from 'styled-components/macro';
import { ifProp, prop } from 'styled-tools';
import { css } from 'styled-components';

interface Props {
  fullHeight?: boolean;
  padding?: number;
}

export const Article = styled.article<Props>`
  padding: ${ifProp('padding', prop('padding'), 2)}rem;
  background: var(--grey-800);
  border-radius: 8px;

  ${ifProp(
    'fullHeight',
    css`
      min-height: 100%;
    `,
  )}
`;

export const ArticleScrollContent = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 -2rem -2rem;
  padding: 0 2rem 2rem 2rem;
`;
