import { useField } from 'formik';
import * as React from 'react';

import { Input } from '../Input';
import { Col, Row } from '../Grid';
import { Label } from '../Label';

export const TextField = props => {
  const { label, name } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <Row flexDirection="column">
      <Col>{typeof label === 'string' ? <Label>{label}</Label> : label}</Col>
      <Col gutterY={0.5}>
        <Input {...field} {...props} invalid={!!(meta.touched && meta.error)} />
      </Col>
    </Row>
  );
};
