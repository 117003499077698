import React from 'react';
import styled from 'styled-components/macro';

interface CircleImageProps {
  imageSrc: string;
  size: number;
  style?: React.CSSProperties;
}

export const CircleImage = styled.div.attrs((props: CircleImageProps) => ({
  style: { backgroundImage: `url(${props.imageSrc})`, ...props.style },
}))<CircleImageProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(var(--white-rgb), 0.1);
`;
