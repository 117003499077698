import * as React from 'react';
import { useField } from 'formik';

import { Col, Row } from '../Grid';
import { Label } from '../Label';
import { DatePicker } from '../DatePicker';

export const DateField = props => {
  const { label, name } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <Row flexDirection="column">
      <Col>
        <Label>{label}</Label>
      </Col>
      <Col gutterY={0.5}>
        <DatePicker
          {...field}
          {...props}
          onChange={value => {
            helpers.setValue(value);
          }}
          invalid={!!(meta.touched && meta.error)}
        />
      </Col>
    </Row>
  );
};
