import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { keyframes } from 'styled-components';

interface ButtonIconProps {
  $loading?: boolean;
  block?: boolean;
}

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const ButtonIcon = styled.button<ButtonIconProps>`
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 0;
  color: ${ifProp('$loading', 'transparent', 'inherit')};
  pointer-events: ${ifProp('$loading', 'none', 'auto')};
  position: relative;
  display: ${ifProp('block', 'block', 'inline-block')};

  &:hover {
    color: var(--gold-30);
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:after {
    ${ifProp(
      '$loading',
      css`
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 2px rgba(255, 255, 255, 0.2);
        border-top-color: #fff;
        animation: ${spinKeyframe} 1s infinite linear;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -10px 0 0 -10px;
      `,
    )}
  }
`;
