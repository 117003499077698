import React from 'react';
import OriginSlider from 'rc-slider';
import styled, { css } from 'styled-components/macro';

const styles = css`
  & {
    position: relative;
    padding: 0.875rem 0;

    .rc-slider-rail {
      height: 0.25rem;
      background: var(--grey-600);
      border-radius: 0.125rem;
    }

    .rc-slider-track {
      position: absolute;
      top: 0.875rem;
      height: 4px;
      background: var(--gold-30);
    }

    .rc-slider-handle {
      width: 2rem;
      height: 2rem;
      background: var(--gold-100);
      border-radius: 50%;
      position: absolute;
      top: 1rem;
      margin-top: -1rem;
      border: 1px solid var(--gold-30);
    }
  }
`;

export const Slider = styled(OriginSlider)`
  ${styles}
`;
