import { useField } from 'formik';
import * as React from 'react';

import { InputNumber } from '../Input';
import { Col, Row } from '../Grid';
import { Label } from '../Label';

export const NumberField = props => {
  const { label, name, onChange } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <Row flexDirection="column">
      <Col>{typeof label === 'string' ? <Label>{label}</Label> : label}</Col>
      <Col gutterY={0.5}>
        <InputNumber
          {...field}
          {...props}
          invalid={!!(meta.touched && meta.error)}
          allowNegative={false}
          onValueChange={({ floatValue }) => {
            helpers.setValue(floatValue);
            onChange && onChange(floatValue);
          }}
          onChange={() => {}}
        />
      </Col>
    </Row>
  );
};
