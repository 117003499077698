import * as React from 'react';
import { Formik, getIn } from 'formik';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, Row } from '../Grid';
import { Filters, FilterType } from '../../../types/Filter';
import { FilterSelectField, FilterSliderField } from '../Fields';
import {
  selectAdmissionOptions,
  selectBodyPartOptions,
  selectCoachTypeOptions,
  selectConferenceOptions,
  selectDivisionOptions,
  selectGenderOptions,
  selectPositionOptions,
  selectProgramOptions,
  selectSemesterOptions,
  selectSportOptions,
} from '../../slice/selectors';
import { Button } from '../Button';
import { Icon } from '../Icon';

interface Props {
  initialValues: Filters;
  filter: Filters;
  onSubmit: (Filters) => void;
  fields: FilterType[];
}

export function FiltersList(props: Props) {
  const { initialValues, filter, onSubmit, fields } = props;
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [showExpandButton, setShowExpandButton] = React.useState(false);
  const outerRef = React.useRef<HTMLDivElement>(null);
  const innerRef = React.useRef<HTMLFormElement>(null);

  const genderOptions = useSelector(selectGenderOptions);
  const semesterOptions = useSelector(selectSemesterOptions);
  const divisionOptions = useSelector(selectDivisionOptions);
  const positionOptions = useSelector(selectPositionOptions);
  const bodyPartOptions = useSelector(selectBodyPartOptions);
  const admissionOptions = useSelector(selectAdmissionOptions);
  const coachTypeOptions = useSelector(selectCoachTypeOptions);
  const conferenceOptions = useSelector(selectConferenceOptions);
  const programOptions = useSelector(selectProgramOptions);
  const sportOptions = useSelector(selectSportOptions);

  const statusOptions = [
    {
      label: t('label.available'),
      value: true,
    },
    {
      label: t('label.unavailable'),
      value: false,
    },
  ];

  const toggleFilter = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    const outerHeight = outerRef?.current?.clientHeight;
    const innerHeight = innerRef?.current?.clientHeight;

    if (innerHeight && outerHeight) {
      setShowExpandButton(innerHeight - (3 / 4) * 16 > outerHeight);
    }
  }, [fields.length]);

  return (
    <Row gutter={2}>
      <Col xs="auto" gutter={2}>
        <Wrapper
          style={{ maxHeight: expanded ? 'none' : '46px' }}
          ref={outerRef}
        >
          <Formik initialValues={filter} onSubmit={onSubmit} enableReinitialize>
            {({ handleSubmit, submitForm }) => (
              <form onSubmit={handleSubmit} ref={innerRef}>
                <Row gutterY={0.75} gutter={0.75}>
                  {fields.map(field => {
                    let filter: React.ReactElement | null = null;

                    switch (field) {
                      case FilterType.AVAILABILITY: {
                        filter = (
                          <FilterSelectField
                            key={field}
                            name={field}
                            placeholder={t('label.status')}
                            options={statusOptions}
                            onBlur={submitForm}
                            isSearchable={false}
                          />
                        );
                        break;
                      }
                      case FilterType.GENDER: {
                        filter = (
                          <FilterSelectField
                            key={field}
                            name={field}
                            placeholder={t('label.gender')}
                            options={genderOptions}
                            onBlur={submitForm}
                            isMulti
                            isSearchable={false}
                          />
                        );
                        break;
                      }
                      case FilterType.START_DATE: {
                        filter = (
                          <FilterSelectField
                            key={field}
                            name={field}
                            placeholder={t('label.startDate')}
                            options={semesterOptions}
                            onBlur={submitForm}
                            isMulti
                            isSearchable={false}
                          />
                        );
                        break;
                      }
                      case FilterType.DIVISION: {
                        filter = (
                          <FilterSelectField
                            key={field}
                            name={field}
                            placeholder={t('label.division')}
                            options={divisionOptions}
                            onBlur={submitForm}
                            isMulti
                            isSearchable={false}
                          />
                        );
                        break;
                      }
                      case FilterType.POSITION: {
                        filter = (
                          <FilterSelectField
                            key={field}
                            name={field}
                            placeholder={t('label.position')}
                            options={positionOptions}
                            onBlur={submitForm}
                            isMulti
                            isSearchable={false}
                          />
                        );
                        break;
                      }
                      case FilterType.FOOT: {
                        filter = (
                          <FilterSelectField
                            key={field}
                            name={field}
                            placeholder={t('label.strongFoot')}
                            options={bodyPartOptions}
                            onBlur={submitForm}
                            isSearchable={false}
                          />
                        );
                        break;
                      }
                      case FilterType.ADMISSION: {
                        filter = (
                          <FilterSelectField
                            key={field}
                            name={field}
                            placeholder={t('label.admission')}
                            options={admissionOptions}
                            onBlur={submitForm}
                            isSearchable={false}
                            isMulti
                          />
                        );
                        break;
                      }
                      case FilterType.COACH_TYPE: {
                        filter = (
                          <FilterSelectField
                            name={field}
                            placeholder={t('label.position')}
                            options={coachTypeOptions}
                            onBlur={submitForm}
                            isSearchable={false}
                            isMulti
                          />
                        );
                        break;
                      }
                      case FilterType.CONFERENCE: {
                        filter = (
                          <FilterSelectField
                            name={field}
                            placeholder={t('label.conference')}
                            options={conferenceOptions}
                            onBlur={submitForm}
                            isSearchable={false}
                            isMulti
                          />
                        );
                        break;
                      }
                      case FilterType.PROGRAM: {
                        filter = (
                          <FilterSelectField
                            name={field}
                            placeholder={t('label.program')}
                            options={programOptions}
                            onBlur={submitForm}
                            isSearchable={false}
                            isMulti
                          />
                        );
                        break;
                      }
                      case FilterType.SAT_DEGREE: {
                        filter = (
                          <FilterSliderField
                            key={field}
                            label={t('label.satDegree')}
                            name={field}
                            onBlur={submitForm}
                            isSearchable={false}
                            min={getIn(initialValues, `${field}[0]`)}
                            max={getIn(initialValues, `${field}[1]`)}
                          />
                        );
                        break;
                      }
                      case FilterType.TOEFL_SCORE: {
                        filter = (
                          <FilterSliderField
                            key={field}
                            label={t('label.toeflScore')}
                            name={field}
                            onBlur={submitForm}
                            isSearchable={false}
                            min={getIn(initialValues, `${field}[0]`)}
                            max={getIn(initialValues, `${field}[1]`)}
                          />
                        );
                        break;
                      }
                      case FilterType.DUOLINGO_SCORE: {
                        filter = (
                          <FilterSliderField
                            key={field}
                            label={t('label.duolingoScore')}
                            name={field}
                            onBlur={submitForm}
                            isSearchable={false}
                            min={getIn(initialValues, `${field}[0]`)}
                            max={getIn(initialValues, `${field}[1]`)}
                          />
                        );
                        break;
                      }
                      case FilterType.GRADE_POINT_AVG: {
                        filter = (
                          <FilterSliderField
                            key={field}
                            label={t('label.gpa')}
                            name={field}
                            onBlur={submitForm}
                            isSearchable={false}
                            min={getIn(initialValues, `${field}[0]`)}
                            max={getIn(initialValues, `${field}[1]`)}
                          />
                        );
                        break;
                      }
                      case FilterType.BUDGET: {
                        filter = (
                          <FilterSliderField
                            key={field}
                            label={t('label.budget')}
                            name={field}
                            onBlur={submitForm}
                            isSearchable={false}
                            min={getIn(initialValues, `${field}[0]`)}
                            max={getIn(initialValues, `${field}[1]`)}
                            prefix="$"
                            unit="/y"
                          />
                        );
                        break;
                      }
                      case FilterType.SPORT: {
                        filter = (
                          <FilterSelectField
                            name={field}
                            placeholder={t('label.sport')}
                            options={sportOptions}
                            onBlur={submitForm}
                            isSearchable={false}
                            isMulti
                          />
                        );
                        break;
                      }
                    }

                    if (!filter) return null;

                    return (
                      <Col gutterY={0.75} gutter={0.75} key={field}>
                        {filter}
                      </Col>
                    );
                  })}
                </Row>
              </form>
            )}
          </Formik>
        </Wrapper>
      </Col>
      {showExpandButton ? (
        <Col gutter={2}>
          <Button iconStart onClick={toggleFilter}>
            <Icon name="filters" />
            {expanded ? t('button.lessFilters') : t('button.moreFilters')}
          </Button>
        </Col>
      ) : null}
    </Row>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;
