import * as React from 'react';
import { useField } from 'formik';

import { Col, Row } from '../Grid';
import { Label } from '../Label';
import { Select } from '../Select';

export const SelectField = props => {
  const { label, name, options } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <Row flexDirection="column">
      <Col>
        <Label>{label}</Label>
      </Col>
      <Col gutterY={0.5}>
        <Select
          {...field}
          {...props}
          options={options}
          invalid={!!(meta.touched && meta.error)}
          onBlur={() => {
            helpers.setTouched(true);
          }}
          onChange={value => {
            helpers.setValue(value);
          }}
        />
      </Col>
    </Row>
  );
};
