import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Icon } from '../Icon';

export interface HeadingProps {
  level?: number;
  weight?: 400 | 600 | 700;
  children: any;
  style?: React.CSSProperties;
}

const fontSize = ({ level }) => {
  switch (level) {
    case 1:
      return 30;
    case 2:
      return 24;
    case 3:
      return 20;
    case 5:
      return 14;
  }

  return 18;
};

const styles = css`
  font-size: ${fontSize}px;
  line-height: ${({ level }) => fontSize({ level }) + 4}px;
  margin: 0;
`;

export const StyledHeading = styled(({ level, children, ...props }) =>
  React.createElement(`h${level}`, props, children),
)`
  ${styles};
  font-weight: ${({ weight }) => weight};
`;

const StyledHeadingArrow = styled(StyledHeading)`
  position: relative;
  display: inline-block;
  padding-right: ${ifProp({ level: 1 }, '32px', '24px')};
  font-weight: 700;

  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    top: 50%;
    margin-top: -12px;
    transform: rotate(-90deg);
  }
`;

const StyledHeadingArrowBack = styled(StyledHeading)`
  position: relative;
  display: inline-block;
  padding-left: ${ifProp({ level: 1 }, '32px', '24px')};
  font-weight: 700;

  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0;
    top: 50%;
    margin-top: -12px;
    transform: rotate(90deg);
  }
`;

StyledHeading.defaultProps = {
  level: 1,
  weight: 600,
};

export const Heading = (props: HeadingProps) => {
  return <StyledHeading {...props} />;
};

export const HeadingWithArrow = (props: HeadingProps) => {
  const { children, ...rest } = props;

  return (
    <StyledHeadingArrow {...rest}>
      {children}
      <Icon name="chevron" />
    </StyledHeadingArrow>
  );
};

export const HeadingWithArrowBack = (props: HeadingProps) => {
  const { children, ...rest } = props;

  return (
    <StyledHeadingArrowBack {...rest}>
      <Icon name="chevron" />
      {children}
    </StyledHeadingArrowBack>
  );
};
