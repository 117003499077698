import { useField } from 'formik';
import * as React from 'react';

import { Col, Row } from '../Grid';
import { Label } from '../Label';
import { Textarea } from '../Textarea';

export const TextAreaField = props => {
  const { label, name } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <Row flexDirection="column">
      <Col>{typeof label === 'string' ? <Label>{label}</Label> : label}</Col>
      <Col gutterY={0.5}>
        <Textarea
          {...field}
          {...props}
          invalid={!!(meta.touched && meta.error)}
        />
      </Col>
    </Row>
  );
};
