import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Link as LinkOrigin } from 'react-router-dom';
import { ifProp, prop } from 'styled-tools';

interface CardProps {
  active?: boolean;
  border?: boolean;
  padding?: number;
  palette?: number;
}

export const cardStyle = css`
  padding: calc(${ifProp('padding', prop('padding'), 1)}rem - 1px);
  background: var(--grey-${prop('palette', 700)});
  border: 1px solid var(--grey-700);
  border-radius: 0.5rem;

  ${ifProp(
    'border',
    css`
      border: 1px solid var(--grey-600);
    `,
  )}

  ${ifProp(
    'active',
    css`
      border-color: var(--gold-30);
    `,
  )}
`;

export const Card = styled.div<CardProps>`
  ${cardStyle};
`;

export const CardLink = styled(LinkOrigin)<CardProps>`
  ${cardStyle};
  display: block;

  &:hover {
    border-color: var(--gold-30);
  }
`;
