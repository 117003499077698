import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { ifProp } from 'styled-tools';

interface Props {
  iconStart?: boolean;
  iconEnd?: boolean;
  children: any;
  type?: 'button' | 'reset' | 'submit';
  primary?: boolean;
  default?: boolean;
  border?: boolean;
  disabled?: boolean;
  to?: string;
  href?: string;
  target?: string;
  loading?: boolean;
  block?: boolean;
  onClick?: (event: React.MouseEvent) => any;
  invalid?: boolean;
  replace?: boolean;
}

export const Button = (props: Props) => {
  const { to, href } = props;

  if (to) return <StyledLink {...props} />;
  if (href) return <Anchor rel="noreferrer" {...props} />;
  return <StyledButton {...props} />;
};

Button.defaultProps = {
  type: 'button',
  height: 40,
  $loading: false,
  block: false,
};

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const buttonStyles = css`
  display: ${ifProp('block', 'block', 'inline-block')};
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  cursor: ${ifProp('disabled', 'default', 'pointer')};
  appearance: none;
  padding: 10px 16px;
  box-sizing: border-box;
  pointer-events: ${ifProp('$loading', 'none', 'auto')};
  background: transparent;
  border-radius: 4px;
  color: var(--white);
  border: 1px solid transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  width: ${ifProp('block', '100%', 'auto')};
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  &:hover {
    background-color: var(--gold-100);
    transition: background-color 0.2s;
  }

  ${ifProp(
    '$loading',
    css`
      color: transparent !important;
    `,
  )}

  ${ifProp(
    'primary',
    css`
      font-weight: 600;
      border-color: ${ifProp('invalid', 'var(--red)', 'var(--gold-30)')};
      background: ${ifProp('invalid', 'var(--red)', 'var(--gold-30)')};

      &:hover {
        background-color: var(--gold-50);
      }

      &[disabled] {
        border-color: var(--grey-600);
        background-color: var(--grey-600);
        color: var(--grey-400);
      }
    `,
  )}

  ${ifProp(
    'border',
    css`
      border-color: ${ifProp('invalid', 'var(--red)', 'var(--gold-30)')};

      &[disabled] {
        border-color: var(--grey-600);
        color: var(--grey-400);
      }
    `,
  )}

  &:hover {
  }

  &[disabled] {
    pointer-events: none;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: ${ifProp('iconStart', '6px', '0')};
    margin-left: ${ifProp('iconEnd', '6px', '0')};
  }

  &:after {
    ${ifProp(
      '$loading',
      css`
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 2px rgba(255, 255, 255, 0.2);
        border-top-color: #fff;
        animation: ${spinKeyframe} 1s infinite linear;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -10px 0 0 -10px;
      `,
    )}
  }
`;

export const StyledLink = styled(({ disabled, height, ...props }) => (
  <Link {...props} />
))`
  ${buttonStyles}
`;

export const Anchor = styled.a`
  ${buttonStyles}
`;

export const StyledButton = styled.button`
  ${buttonStyles}
`;
